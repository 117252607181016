import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const Wrapper = styled.footer`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
`

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.secondary};
  padding: 1em 0 2em;
  margin: 0 1.5em;
`

const Item = styled.li`
  display: inline-block;
  padding: 0.25em 0;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  a {
    font-weight: 500;
    transition: all 0.2s;
    color: ${props => props.theme.colors.primary};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }
`

const Footer = () => (
  <Wrapper>
    <List>
      <Item>
        <Link to="/">EL INDUSTRIAL</Link>
        {'   |   '}
        <Link to="/acerca/">Acerca</Link>
        {'   |   '}
        <Link to="/contacto/">Contacto</Link>
      </Item>
      <Item>
        <a
          href="https://www.facebook.com/ElIndustrialMx-100103642215455"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
        {'   |   '}
        <a
          href="https://twitter.com/ElIndustrialMx"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter (@ElIndustrialMx)
        </a>
      </Item>
    </List>
  </Wrapper>
)

export default Footer
