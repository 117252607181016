import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'
import { StaticImage } from 'gatsby-plugin-image'

const LogoImg = styled.logoimg`
  img {
    width: 30px;
    height: 30px;
  }
`

const Slogan = styled.slogan`
  color: white;
  font-size: 1em;
`

const activeLinkStyle = {
  color: 'white',
}

const Menu = () => {
  const { menuLinks } = useSiteMetadata()
  return (
    <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="/">
          <Link to="/" activeStyle={activeLinkStyle}>
            <StaticImage
              src="../images/logo2.png"
              alt="logo"
              placeholder="blurred"
              layout="fixed"
              width={78}
              height={50}
            />
          </Link>
        </Navbar.Brand>
        <Slogan className="d-none d-sm-none d-md-block">
          Noticias de negocios de la zona norte
        </Slogan>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <NavDropdown title="Secciones" id="collasible-nav-dropdown">
              {menuLinks.map(link => (
                <Link to={link.slug} activeStyle={activeLinkStyle}>
                  <NavDropdown.Item href={link.slug}>
                    {link.name}
                  </NavDropdown.Item>
                </Link>
              ))}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Menu
